import { observer } from 'mobx-react-lite';
import cls from '../styles.module.scss';
import { useMemo } from 'react';
import { EmployeesTable } from './employees-table';
import { addSpaces } from 'assets/utils';
const sum = [158333,200399,200399,10271,2300,2187,100431,172000,172990,100431,0,0];

const arr = Array.from(Array(10).keys());


const GeneralInformationComponent = () => {
  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[]);
  const tables = arr.map(a =>
    <EmployeesTable key={a} />
  );

  return (
    <div>
      {tables}
      <table style={{ position: 'sticky',bottom: '0' }} className={cls['table']}>
        <colgroup>
          <col width={40} />
          <col width={207} />
          <col width={181} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={73} />
          <col width={73} />
          <col width={73} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
        </colgroup>
        <tbody className={`${cls['tfoot']} ${cls['total-tfoot']}`}>
          <tr className={cls['tr']}>
            <td className={cls['td']} colSpan={3}>Всего</td>
            {sumData}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const GeneralInformation = observer(GeneralInformationComponent);