import { FC,ReactNode,useEffect,useRef } from 'react';
import cls from '../styles.module.scss';

interface UnitsProps {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
  unitListData: ReactNode[];
}

export const Units: FC<UnitsProps> = ({ parentRef,unitListData }) => {
  const refed = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!refed || !refed.current || !parentRef.current) return;

    const parent = parentRef;

    const handler = (e: Event) => {
      const x = (e.currentTarget as any).scrollLeft;

      if (x <= 4) {
        if (refed.current!.style.visibility !== 'hidden')
          refed.current!.style.zIndex = '-1';
        refed.current!.style.visibility = 'hidden';
        return;
      }
      if (refed.current!.style.visibility !== 'visible') {
        refed.current!.style.zIndex = '2';
        refed.current!.style.visibility = 'visible';
      }
      refed.current!.style.left = `${x}px`;
    };
    parent.current?.addEventListener('scroll',handler);
    return () => parent.current?.removeEventListener('scroll',handler);
  },[parentRef]);


  return <div ref={refed} style={{ position: 'absolute',visibility: 'hidden',zIndex: -1 }} className={`${cls['employees-wrapper']}`}>{unitListData}</div>;
};
