import { observer } from 'mobx-react-lite';
import { FC,useEffect,useMemo,useRef } from 'react';
import { useStore } from 'store';
import { DateHeader } from './date-header';
import cls from './date-row-styles.module.scss';

interface DateRowProps {
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const DateRowComponent: FC<DateRowProps> = ({ parentRef }) => {
  const { RootStore: { SchedulePlanningStore: { selectedDate } } } = useStore();
  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth();
  const days = new Date(year,month + 1,0).getDate();
  const arr = Array.from(Array(days).keys());
  arr.splice(15,0,100);
  arr.splice(arr.length,0,100,100);

  const refed = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!refed || !refed.current || !parentRef.current) return;

    const parent = parentRef;

    const handler = (e: Event) => {
      const y = (e.currentTarget as any).scrollTop;

      if (y <= 4) {
        if (refed.current!.style.visibility !== 'hidden') {
          refed.current!.style.zIndex = '-1';
          refed.current!.style.visibility = 'hidden';
        }
        return;
      }
      if (refed.current!.style.visibility !== 'visible') {
        refed.current!.style.zIndex = '1';
        refed.current!.style.visibility = 'visible';
      }
      refed.current!.style.top = `${y}px`;
    };
    parent.current?.addEventListener('scroll',handler);
    return () => parent.current?.removeEventListener('scroll',handler);
  },[parentRef]);

  const dateHeaders = useMemo(() => {
    return arr.map((d,i,arr) =>
      <DateHeader key={i} date={new Date(year,month,d + 1)} isLast={i === arr.length - 1} timeSum={d === 100} />
    );
  },[arr,month,year]);

  return <div ref={refed} style={{ position: 'absolute',left: '274px',visibility: 'hidden',zIndex: -1 }} className={cls['container']}>{dateHeaders}</div>;
};

export const DateRow = observer(DateRowComponent);