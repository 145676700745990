import { FC,useState } from 'react';
import { MonthSelector } from 'widgets/month-selector';
import cls from '../styles.module.scss';
import { Segmented } from 'antd';
import { FundTabs } from '../types';

interface FundHeaderProps {
  activeTab: FundTabs;
  setActiveTab: (tab: FundTabs) => void;
}

const FundHeaderComponent: FC<FundHeaderProps> = ({ activeTab,setActiveTab }) => {

  const [selectedDate,setSelectedDate] = useState(new Date());

  return (<div className={cls['fund__header']}>
    <MonthSelector selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
    <Segmented
      className={cls['tabs']}
      value={FundTabs[activeTab]}
      options={['Общая информация','Вознаграждения за смены','Контракты','Премии','Управляющий']}
      onChange={(val: string) => setActiveTab((FundTabs as any)[val])}
    />
  </div>);
};

export const FundHeader = FundHeaderComponent;