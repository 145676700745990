import { observer } from "mobx-react-lite";
import cls from './styles.module.scss';
import { FundHeader } from './components/header';
import { GeneralInformation } from './general/general-information';
import { useMemo,useState } from 'react';
import { FundTabs } from './types';

//todo loader, second array, outside table click, save call
const SalaryFundComponent = () => {
  const [activeTab,setActiveTab] = useState(FundTabs['Общая информация']);

  const activeView = useMemo(() => {
    switch (activeTab) {
      case FundTabs['Общая информация']:
        return <GeneralInformation />;
      default:
        return null;
    }
  },[activeTab]);

  return <div className={cls['fund__wrapper']}>
    <h1 className={cls['fund-title']}>Вознаграждения</h1>
    <FundHeader activeTab={activeTab} setActiveTab={setActiveTab} />
    {activeView}
  </div>;
};

export const SalaryFundPage = observer(SalaryFundComponent);