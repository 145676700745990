import { FC,useMemo } from 'react';
import cls from '../styles.module.scss';
import { addSpaces } from 'assets/utils';

const sum = [158333,200399,200399,10271,2300,2187,100431,172000,172990,100431,0,0];
const employees = [
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
  { name: 'Соколова Алиса',post: 'Управляющий',month: [23989,33989,33989],tax: [1405,749,200],prepaid: [10113,10113,10113],salary: [10113,0,0] },
];

interface EmployeesTableProps {

}

const EmployeesTableComponent: FC<EmployeesTableProps> = () => {
  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[]);
  const employeesData = useMemo(() => employees.map((emp,i) => <tr>
    <td className={`${cls['td']} ${cls['employee']} ${cls['num']}`}>{i + 1}</td>
    <td className={`${cls['td']} ${cls['employee']}`}><span title={emp.name}>{emp.name}</span></td>
    <td className={`${cls['td']} ${cls['employee']}`}><span title={emp.name}>{emp.post}</span></td>
    {emp.month.map(m => <td className={cls['td']}>{addSpaces(m)}</td>)}
    {emp.tax.map(t => <td className={cls['td']}>{addSpaces(t)}</td>)}
    {emp.prepaid.map(p => <td className={cls['td']}>{addSpaces(p)}</td>)}
    {emp.salary.map(s => <td className={cls['td']}>{addSpaces(s)}</td>)}
  </tr>
  ),[]);

  return (
    <table className={cls['table']}>
      <colgroup>
        <col width={40} />
        <col width={207} />
        <col width={181} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={73} />
        <col width={73} />
        <col width={73} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
      </colgroup>
      <thead className={cls['thead']}>
        <tr className={cls['tr']}>
          <th className={cls['th']} colSpan={3}>Работники Бара</th>
          <th className={cls['th']} colSpan={3}>За месяц</th>
          <th className={cls['th']} colSpan={3}>Налоги за месяц</th>
          <th className={cls['th']} colSpan={3}>Аванс</th>
          <th className={cls['th']} colSpan={3}>Зарплата</th>
        </tr>
        <tr className={cls['tr']}>
          <th className={cls['th']}>№</th>
          <th className={cls['th']}>Работник</th>
          <th className={cls['th']}>Должность</th>
          <th className={cls['th']}>Запланировано</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
          <th className={cls['th']}>НДФЛ</th>
          <th className={cls['th']}>ЕСН</th>
          <th className={cls['th']}>ФСС</th>
          <th className={cls['th']}>Запланировано</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
          <th className={cls['th']}>Запланировано</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
        </tr>
      </thead>
      <tfoot className={cls['tfoot']}>
        <tr className={cls['tr']}>
          <td className={cls['td']} colSpan={3}>Всего</td>
          {sumData}
        </tr>
      </tfoot>
      <tbody className={cls['tbody']}>
        {employeesData}
      </tbody>
    </table>
  );
};

export const EmployeesTable = EmployeesTableComponent;