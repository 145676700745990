import { makeAutoObservable } from "mobx";
import { RootStore } from "../../store/rootStore";

export class SalaryFundStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  selectedDate = new Date();

  setSelectedDate = (date: Date) => {
    this.selectedDate = date;
  };
}
