import React, { useEffect } from "react";
import {
  Input,
  EInputVariant,
  EInputStyleVariant,
  Logo,
  ELogoVariant,
  Button,
  EButtonVariant,
  Checkbox,
  ECheckboxChecked,
  ECheckboxUnchecked,
  Spinner,
  ESpinnerVariant,
} from "ui";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { useStore } from "store";
import { EFontVariant } from "assets/styles/enums";
import Cookies from "js-cookie";

export const FirstAuthPage = observer(() => {
  const {
    RootStore: {
      AuthStore: {
        isLoading,
        setIsLoading,
        password,
        passwordRepeat,
        passwordVision,
        setPassword,
        setPasswordRepeat,
        setPasswordVision,
        passwordRepeatVision,
        setSavePassword,
        setPasswordRepeatVision,
        savePassword,
      },
    },
  } = useStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (Cookies.get("token")) {
      navigate("/main/reports");
    }
  }, []);

  const setFetch = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/registration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: "333",
            password: password,
            password_confirm: passwordRepeat,
          }),
        },
      );

      const responseData = await response.json();
      setIsLoading(false);
      Cookies.set("token", responseData.data.token);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const testName = "TestName";
  return (
    <div className="firstAuth__wrapper">
      <div className="firstAuth__container">
        <div className="firstAuth__logo-container">
          <Logo variant={ELogoVariant.logo1} />
        </div>
        <span className="firstAuth__line"></span>
        <div className="firstAuth__authContainer">
          <h2 className={`firstAuth__authContainer-header`}>Создание пароля</h2>
          <span className={`firstAuth__authContainer-wellcome_message`}>
            Привет, {testName}! Для входа придумай длинный и запоминающийся
            пароль.
          </span>
          <label className="firstAuth__authContainer-first_pass">
            <span
              className={`firstAuth__authContainer-first_pass__text-container`}
            >
              Ваш пароль
            </span>
            <Input
              type={
                passwordVision ? EInputVariant.text : EInputVariant.password
              }
              variant={EInputStyleVariant.basicInput}
              height={"44px"}
              borderRadius={"16px"}
              value={password}
              autoFocus={true}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <div className="firstAuth__authContainer-first_pass__button-container">
              {passwordVision ? (
                <Button
                  variant={EButtonVariant.eyeButton}
                  maxWidth={"24px"}
                  height={"24px"}
                  onClick={() => setPasswordVision(false)}
                />
              ) : (
                <Button
                  variant={EButtonVariant.shapeButton}
                  maxWidth={"24px"}
                  height={"24px"}
                  onClick={() => setPasswordVision(true)}
                />
              )}
            </div>
          </label>
          <label className="firstAuth__authContainer-second_pass">
            <span
              className={`firstAuth__authContainer-second_pass__text-container`}
            >
              Повторите пароль
            </span>
            <Input
              type={
                passwordRepeatVision
                  ? EInputVariant.text
                  : EInputVariant.password
              }
              variant={EInputStyleVariant.basicInput}
              height={"44px"}
              borderRadius={"16px"}
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.currentTarget.value)}
            />
            <div className="firstAuth__authContainer-second_pass__button-container">
              {passwordRepeatVision ? (
                <Button
                  variant={EButtonVariant.eyeButton}
                  maxWidth={"24px"}
                  height={"24px"}
                  onClick={() => setPasswordRepeatVision(false)}
                />
              ) : (
                <Button
                  variant={EButtonVariant.shapeButton}
                  maxWidth={"24px"}
                  height={"24px"}
                  onClick={() => setPasswordRepeatVision(true)}
                />
              )}
            </div>
          </label>
          <label className="firstAuth__checkbox-container">
            <Checkbox
              checkedVariant={ECheckboxChecked.checkedVariant1}
              uncheckedVariant={ECheckboxUnchecked.uncheckedVariant1}
              styles={{ marginRight: 12 }}
              onChange={() => {
                savePassword === false
                  ? setSavePassword(true)
                  : setSavePassword(false);
              }}
            />
            <span className={`firstAuth__fill-text`}>Запомнить меня</span>
          </label>
          {password !== "" &&
          password.length >= 3 &&
          passwordRepeat !== "" &&
          passwordRepeat.length >= 3 &&
          password === passwordRepeat ? (
            isLoading ? (
              <Spinner
                variant={ESpinnerVariant.spinnerGeneral}
                maxWidth={"336px"}
                height={"48px"}
                borderRadius={"16px"}
              />
            ) : (
              <Button
                variant={EButtonVariant.fillButton}
                font={EFontVariant.body2Regular}
                height={"48px"}
                borderRadius={"16px"}
                text="Войти в систему"
                onClick={() => {
                  console.log("Типо пошёл вход");
                  setFetch();
                  setIsLoading(true);
                }}
              />
            )
          ) : (
            <Button
              variant={EButtonVariant.fillButton}
              font={EFontVariant.body2Regular}
              disabled={true}
              height={"48px"}
              borderRadius={"16px"}
              text="Войти в систему"
            />
          )}
        </div>
      </div>
    </div>
  );
});
